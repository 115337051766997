@import '../../styles/colors.scss';
.frontpage {

    animation: pagefadein .3s forwards;

    background-color: $kleur-achtergrond;
    .intro {
        margin-top: 60px;
        //display: flex;
        justify-content: space-between;
        background-color: $kleur-achtergrond-intro;
        padding: 30px 30px 30px 30px;
    }
    .intro-text {
        font-family: 'SourceSans-Regular', sans-serif;
        max-width: 500px;
        margin: 0;
        padding: 21px 30px 30px 0;
        font-size: 16px;
        line-height: 24px;
        color: $kleur-bijnazwart;
        padding-bottom: 30px;
    }
    .intro-buttons {
        flex: 0 0 400px;
        button {
            font-family: 'SourceSans-Semibold', sans-serif;
            position: relative;
            display: block;
            width: 100%;
            max-width: 400px;
            height: 60px;
            border-radius: 9px;
            text-align: left;
            border: none;
            background-color: $kleur-bruin;
            -webkit-appearance: none;
            font-size: 18px;
            margin: 3px 0 12px 0;
            padding: 0 12px;
            cursor: pointer;
            color: #fff;
            .pijl {
                position: absolute;
                top: 12px;
                right: 0px;
                stroke: #fff;
                stroke-width: 4.5;
                width: 37px;
                height: 37px;
            }
        }

        button {
            transition: transform 0.3s ease;
        }
        button:hover {
            transform: scale(1.02);
        }

    }
    .page-title {
        font-family: 'SourceSans-Semibold', sans-serif;
        color: #fff;
        margin: 0;
        padding: 30px 30px;
        font-size: 18px;
        letter-spacing: 1px;
    }
    .page-cards {
        margin: 0;
        padding: 0 30px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .frontpage {
        .intro {
            padding: 30px 30px 30px 60px;
        }
        .intro-text {
            font-size: 18px;
            padding: 12px 30px 30px 0;
        }
        .intro-buttons {
            button {
                font-size: 21px;
            }
        }
        .page-title {
            font-size: 21px;
            padding: 30px 60px;
        }
        .page-cards {
            padding: 0 60px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .frontpage {
        .intro {
            display: flex;
            padding: 30px 30px 30px 60px;
        }
        .intro-text {
            font-size: 18px;
            padding: 0 30px 0 0;
        }
        .page-title {
            font-size: 30px;
            padding: 30px 60px;
        }
    }
}

