$kleur-fabrieksblauw: #009FE3;
$kleur-dadagroen: #77BC1F;
$kleur-bijnazwart: #333;

/*
$kleur-groen0: #006231;
$kleur-groen1: #00481E;
$kleur-groen2: #006231;
$kleur-groen3: #00823A;
$kleur-groen4: #009E3D;
$kleur-groen5: #74B56E;


$kleur-paars0: #6C2D69;
$kleur-paars1: #8C377B;
$kleur-paars2: #9F5794;

$kleur-blauw0: #275D90;
$kleur-blauw1: #0056A4;
$kleur-blauw2: #3873B9
*/

//kleuren keuzehulp fertiliteitspreservatie
$kleur-bijnazwart: #333;
$kleur-achtergrond: #00619F; //donkerblauw
$kleur-achtergrond-intro: #CBE9FC; //#CBDFEB;

$kleur-oranje: #e36307; //#F39200;
$kleur-blauw: #009FE3;
$kleur-groen: #009640;
$kleur-paars: #662483;
$kleur-bruin: #823C0C;

//tinten in harmonica
//$kleur-wijk-achtergrond: rgba([$kleurWijk], .15);
//$kleur-wijk-hoofdstraat: rgba([$kleurWijk], .25);
