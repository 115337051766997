@import '../../styles/colors.scss';
.page-cards {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.card {
    position: relative;
    width: 100%;
    //height: 200px;
    border-radius: 9px;
    background-color: #fff;
    overflow: hidden;
    margin: 0 0 21px 0;
    cursor: pointer;
    .card-title {
        font-family: 'SourceSans-Bold', sans-serif;
        margin: 0;
        box-sizing: border-box;
        padding: 15px;
        background-color: #ccc;
        font-size: 20px;
        color: #fff;
        letter-spacing: 1px;
        user-select: none;
    }
    .card-text {
        padding: 0px 60px 0 12px;
        font-size: 17px;
        line-height: 24px;
        margin: 12px 0;
        min-height: 45px;
        user-select: none;
    }
    .card-button {
        position: absolute;
        right: 12px;
        bottom: 12px;
        border: none;
        background-color: #ccc;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        cursor: pointer;
        padding: 4px 3px 4px 5px;
        .pijl {
            stroke: #fff;
            stroke-width: 4.5;
        }
    }
    .card-button {
        transition: transform 0.3s ease;
        transform-origin: 50% 50%;
    }
    .card-button:hover {
        transform: scale(1.1);
    }
}

.card1 {
    .card-title,
    .card-button {
        background-color: $kleur-oranje;
    }
}

.card2 {
    .card-title,
    .card-button {
        background-color: $kleur-blauw;
    }
}

.card3 {
    .card-title,
    .card-button {
        background-color: $kleur-groen;
    }
}

.card4 {
    .card-title,
    .card-button {
        background-color: $kleur-paars;
    }
}

@media (min-width: 510px) {
    .card {
        width: 194px;
        height: 276px;
        margin: 0 21px 21px 0;
        .card-title {
            min-height: 96px;
            line-height: 24px;
        }
        .card-text {
	        padding: 0px 12px;
	      }
    }
}
